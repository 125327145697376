.appointment-view {
  &__block {
    margin-bottom: 2rem;

    &-content {
      border: 1px solid #dfdfdf;
      border-radius: 7px;
      padding: 1rem;
    }

    &-title {
      font-size: 1.6rem;
      margin-bottom: 1rem;
      color: var(--color-primary);
    }
  }

  &__patient-note {
    margin-bottom: 2rem;

    p {
      min-height: 1.3rem;
    }
  }

  &__preview-gp-letter {
    margin-top: 2rem;
  }
}
