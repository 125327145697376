.form-success {
  margin-bottom: 2rem;
  background: var(--color-success-background);
  color: var(--color-success-text);
  padding: 1.5rem;
  border-radius: 6px;
  white-space: pre-wrap;

  p {
    margin-bottom: 1.5rem;
  }

  p:last-child {
    margin-bottom: 0;
  }

  a {
    color: var(--color-error-text);
    text-decoration: underline;
  }
}
