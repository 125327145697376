.past-appointments {
    padding-top: var(--content-padding-top);

    &__title {
        font-size: 2rem;
    }

    &__sub-title {
        color: var(--color-text-primary);
        margin-bottom: 2rem;
    }

    &__list {
        max-width: var(--table-max-width);
        margin: 3rem auto 0;
        padding: var(--table-padding);
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;
    }

    &__select-practitioner, &__search-patient {
        margin-left: 0.5rem;
    }

    &__table {
        padding: var(--table-padding);
        width: 100%;
        max-width: var(--table-max-width);
        margin: 0 auto;

        td, th {
            text-align: left;
            padding: var(--table-cell-padding);

            .button {
                margin-bottom: 0;
            }
        }
    }

    &__input {
        font-size: var(--font-size-default);
        font-weight: 400;
        font-family: var(--font-family);
        color: var(--color-text-primary);
        padding: 1rem;
        border: 1px solid var(--color-primary);
        border-radius: 7px;
        flex: 1;
        margin-left: 1rem;

        &:active,
        &:focus {
            outline: none;
            border: 2px solid var(--color-primary-darker);
        }
    }
    + .authRoute__version {
        max-width: var(--table-max-width);
        padding: 0 var(--table-padding);
        margin: 0 auto;
    }
}
