.upcoming-appointment {
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;

  &__info {
    display: flex;
    align-items: center;
  }

  &__avatar {
    flex: 0 0 14rem;
    max-width: 14rem;
    border-radius: 50%;
    margin-bottom: 2rem;
  }

  &__details {
    flex: 1;
  }

  &__date {
    font-weight: 600;
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  &__time {
    font-size: 1.6rem;
  }

  &__actions {
    display: flex;
    border-top: 1px solid #eeeeee;
    padding: 0.5rem 0.5rem 0 0.5rem;
    align-items: center;
    justify-content: center;
    padding-top: 1.5rem;
    font-size: 1.6rem;
  }

  &__action-required {
    color: var(--color-error-text);
    margin-bottom: 1rem;
  }
}
