.navigation-nav-bar {
  display: flex;
  background: #fff;
  margin-right: 2rem;
  width: auto;
  min-width: 30rem;

  &__tab {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 0.5rem 0 0.5rem;
    flex: 1;
    color: #868686;
    margin-left: 1.4rem;
    cursor: pointer;

    &--active {
      color: var(--color-primary);
    }
  }

  &__label {
    font-weight: 300;
    font-size: 1.5rem;
    padding-left: 0.5rem;
  }

  &__icon {
    font-size: 2.4rem;
  }
}
