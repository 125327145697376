.patient-notes-editor {
  font-size: var(--font-size-default);
  font-weight: 400;
  font-family: var(--font-family);
  color: var(--color-text-primary);
  padding: 0.75rem 1rem;
  border: 1px solid var(--color-primary);
  border-radius: 7px;
  width: 100%;
  margin-top: 0.4rem;

  .DraftEditor-root {
    max-height: 1000rem;
    overflow-y: scroll;
  }
}
