@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap);
:root{--color-primary: #50bcbd;--color-primary-darker: #1ba0a2;--color-text-primary: #545454;--color-error-text: #bd5050;--color-error-background: #ffefef;--color-success-text: #3e6c08;--color-success-background: #daeecd;--width-desktop: 65rem;--width-desktop-smaller: 35em;--content-padding-top: 10rem;--table-cell-padding: 1rem;--table-padding: 3rem;--table-max-width: 1720px}*,*::after,*::before{margin:0;padding:0;box-sizing:inherit}html{font-size:62.5%}body{box-sizing:border-box;background:var(--color-background);font-family:"Montserrat",sans-serif;font-weight:400;font-size:1.3rem;line-height:1.8rem;color:#545454;color:var(--color-text-primary)}a{color:#50bcbd;color:var(--color-primary);text-decoration:none}strong{font-weight:600}

.loading-spinner{min-height:4.8rem;display:flex;justify-content:center;align-items:center}.loading-spinner--fullscreen{width:100%;height:100vh}.loading-spinner__dots{margin:0 auto 0;width:70px;text-align:center}.loading-spinner__bounce{width:18px;height:18px;background-color:var(--color-primary);border-radius:100%;display:inline-block;-webkit-animation:loading-spinner-bounce 1.4s infinite ease-in-out both;animation:loading-spinner-bounce 1.4s infinite ease-in-out both}.loading-spinner__bounce--1{-webkit-animation-delay:-0.32s;animation-delay:-0.32s}.loading-spinner__bounce--2{-webkit-animation-delay:-0.16s;animation-delay:-0.16s}@-webkit-keyframes loading-spinner-bounce{0%,80%,100%{-webkit-transform:scale(0)}40%{-webkit-transform:scale(1)}}@keyframes loading-spinner-bounce{0%,80%,100%{-webkit-transform:scale(0);transform:scale(0)}40%{-webkit-transform:scale(1);transform:scale(1)}}
.activate-account{margin:0 auto;max-width:var(--width-desktop-smaller)}.activate-account-content{padding-top:var(--content-padding-top) !important}.activate-account-actions{margin-top:5rem;display:block}
.content{padding:0 2rem;margin:0 auto;max-width:var(--width-desktop)}
.page-header{display:flex;align-items:center;padding-right:1rem;padding-left:1rem;padding-bottom:1rem;position:fixed;width:100%;background:#fff;top:0;left:0;border-bottom:1px solid #dfdfdf;z-index:999}.page-header__left{display:flex;align-items:center;min-width:30rem}.page-header__back{margin-right:.5rem;cursor:pointer;color:var(--color-primary);font-size:2.4rem;order:1;margin-top:1rem}.page-header__title{margin:1rem 0 0 0 !important;order:2;font-size:2rem}.page-header__logo{background-color:rgba(0,0,0,0);border:none;margin:1rem 1rem 0 0;width:7rem;order:0}.page-header-info{border:1px solid var(--color-primary);border-radius:5px;color:var(--color-primary);padding:.5rem 1rem 1rem 1rem;display:flex;border-radius:10px;margin:.5rem auto 0 auto}.page-header-info__block{margin-right:8rem}.page-header-info__block:last-child{margin-right:0}.page-header-info__title{margin-bottom:1rem}.page-header-info__time{font-size:3rem}.page-header-info__extra{margin-bottom:.5rem}
.navigation-nav-bar{display:flex;background:#fff;margin-right:2rem;width:auto;min-width:30rem}.navigation-nav-bar__tab{display:flex;flex-direction:row;align-items:center;padding:0 .5rem 0 .5rem;flex:1 1;color:#868686;margin-left:1.4rem;cursor:pointer}.navigation-nav-bar__tab--active{color:var(--color-primary)}.navigation-nav-bar__label{font-weight:300;font-size:1.5rem;padding-left:.5rem}.navigation-nav-bar__icon{font-size:2.4rem}
.logo{display:block;width:100%}
.text-title--main{font-size:2rem;color:var(--color-primary);margin-bottom:2rem}.text-title--sub{font-size:1.6rem;color:var(--color-primary);margin-bottom:1.2rem}.text-title.mb-10{margin-bottom:1rem}
.form-error{margin-bottom:2rem;background:var(--color-error-background);color:var(--color-error-text);padding:1.5rem;border-radius:6px;white-space:pre-wrap}.form-error p{margin-bottom:1.5rem}.form-error p:last-child{margin-bottom:0}.form-error a{color:var(--color-error-text);text-decoration:underline}
.form-text-input{margin-bottom:2rem;display:flex}.form-text-input__label{font-size:1.6rem;color:var(--color-text-primary);flex:1 1}.form-text-input__field{font-size:var(--font-size-default);font-weight:400;font-family:var(--font-family);color:var(--color-text-primary);padding:.75rem 1rem;border:1px solid var(--color-primary);border-radius:7px;width:100%;display:block;min-width:100%;background:rgba(0,0,0,0);min-height:42px;margin-top:.4rem}.form-text-input__field:active,.form-text-input__field:focus{outline:none;border:2px solid var(--color-primary-darker)}.form-text-input__error{display:block;margin-top:.5rem;color:var(--color-error)}.form-text-input__remove{align-self:flex-end;margin-bottom:1rem;margin-left:1rem;cursor:pointer}
.field-error{font-size:1.3rem;margin-top:.5rem;color:var(--color-error-text)}.field-error__list{margin-left:1.3rem}
@-webkit-keyframes button-spinner{to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes button-spinner{to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}.button{border-radius:6px;font-weight:400;text-align:center;text-transform:uppercase;margin-bottom:1rem;cursor:pointer;border:none}.button--size-small{padding:.6rem 1.6rem;font-size:1.3rem;line-height:1.8}.button--size-medium{padding:.4rem 1rem;font-size:1.3rem;line-height:1.8}.button--size-large{padding:1rem 1.6rem;font-size:1.8rem;line-height:2.2}.button--color-primary{background:var(--color-primary);box-shadow:0px 2px 1px rgba(0,0,0,.2),0px 2px 2px rgba(0,0,0,.14),0px 1px 5px rgba(0,0,0,.12);color:#fff}.button--color-primary:hover{background:var(--color-primary-darker)}.button--color-primary-outline{border:1px solid var(--color-primary);background:rgba(0,0,0,0);color:var(--color-primary)}.button--color-primary-link{border:none;background:rgba(0,0,0,0);color:var(--color-primary);text-transform:none}.button--color-secondary{background:#fff;box-shadow:0px 2px 1px rgba(0,0,0,.2),0px 2px 2px rgba(0,0,0,.14),0px 1px 5px rgba(0,0,0,.12);color:var(--color-primary)}.button--color-secondary-outline{border:1px solid #fff;background:rgba(0,0,0,0);color:#fff}.button--color-danger-outline{border:1px solid #ee6c6c;background:rgba(0,0,0,0);color:#ee6c6c}.button.w-full{width:100%}.button:disabled,.button .disabled{background:#c4c4c4;color:gray;opacity:.8;border:1px solid gray;cursor:not-allowed;box-shadow:none}.button.loading{background:rgba(0,0,0,0);color:rgba(0,0,0,0);border:1px solid #c4c4c4;opacity:.8;cursor:progress;box-shadow:none;position:relative}.button.loading:before{content:"";box-sizing:border-box;position:absolute;top:50%;left:50%;width:20px;height:20px;margin-top:-10px;margin-left:-10px;border-radius:50%;border:2px solid var(--color-primary-darker);border-top-color:var(--color-primary);-webkit-animation:button-spinner .6s linear infinite;animation:button-spinner .6s linear infinite}
.password-and-confirm__password{display:flex;align-items:flex-start}.password-and-confirm__input{flex:1 1}.password-and-confirm__icon{margin-top:3rem;font-size:2.2rem}
.login-content{padding-top:var(--content-padding-top)}.login-actions{margin-top:5rem;display:block}
.forgotten-password{position:relative;margin:0 auto;max-width:var(--width-desktop-smaller)}.forgotten-password-content{padding-top:var(--content-padding-top)}
.text-body--default{font-size:1.3rem;color:var(--color-text-primary);margin-bottom:1rem}.text-body--emphasis{font-weight:bold}.text-body--small{font-size:1.5rem;font-style:italic;margin-bottom:2.2rem}.text-body ul,.text-body ol{margin:1rem 0 1.5rem 0}.text-body li{margin:0 0 1rem 1.5rem}
.home{padding-top:var(--content-padding-top)}.home__title{font-size:2rem}.home__sub-title{color:var(--color-text-primary);margin-bottom:2rem}.home-next-appointments{background:rgba(80,188,189,.07);margin-bottom:2rem;padding:2rem 0}.home-upcoming-appointments{margin-top:3rem}
.upcoming-appointment{cursor:pointer;background:#fff;border:1px solid #eee;box-sizing:border-box;box-shadow:0px 3px 14px rgba(0,0,0,.12);border-radius:6px;padding:1.5rem;margin-bottom:1.5rem}.upcoming-appointment__info{display:flex;align-items:center}.upcoming-appointment__avatar{flex:0 0 14rem;max-width:14rem;border-radius:50%;margin-bottom:2rem}.upcoming-appointment__details{flex:1 1}.upcoming-appointment__date{font-weight:600;font-size:2rem;margin-bottom:1rem}.upcoming-appointment__time{font-size:1.6rem}.upcoming-appointment__actions{display:flex;border-top:1px solid #eee;padding:.5rem .5rem 0 .5rem;align-items:center;justify-content:center;padding-top:1.5rem;font-size:1.6rem}.upcoming-appointment__action-required{color:var(--color-error-text);margin-bottom:1rem}
.past-appointments{padding-top:var(--content-padding-top)}.past-appointments__title{font-size:2rem}.past-appointments__sub-title{color:var(--color-text-primary);margin-bottom:2rem}.past-appointments__list{max-width:var(--table-max-width);margin:3rem auto 0;padding:var(--table-padding)}.past-appointments__header{display:flex;justify-content:space-between;align-items:center;margin-bottom:2rem}.past-appointments__select-practitioner,.past-appointments__search-patient{margin-left:.5rem}.past-appointments__table{padding:var(--table-padding);width:100%;max-width:var(--table-max-width);margin:0 auto}.past-appointments__table td,.past-appointments__table th{text-align:left;padding:var(--table-cell-padding)}.past-appointments__table td .button,.past-appointments__table th .button{margin-bottom:0}.past-appointments__input{font-size:var(--font-size-default);font-weight:400;font-family:var(--font-family);color:var(--color-text-primary);padding:1rem;border:1px solid var(--color-primary);border-radius:7px;flex:1 1;margin-left:1rem}.past-appointments__input:active,.past-appointments__input:focus{outline:none;border:2px solid var(--color-primary-darker)}.past-appointments+.authRoute__version{max-width:var(--table-max-width);padding:0 var(--table-padding);margin:0 auto}
.field-select{font-size:var(--font-size-default);font-weight:400;font-family:var(--font-family);color:var(--color-text-primary);padding:.9rem 1rem;border:1px solid var(--color-primary);border-radius:7px;background:rgba(0,0,0,0);height:42px;margin-top:.4rem;flex:1 1;margin-left:1rem}.field-select:active,.field-select:focus{outline:none;border:2px solid var(--color-primary-darker)}.field-select:first-child{margin-left:0}.field-select:required:invalid{color:gray}.field-select option[value=""][disabled]{display:none}.field-select option{color:#000}
.alert-error{background:rgba(0,0,0,.5);position:fixed;top:0;bottom:0;left:0;right:0;display:flex;align-items:center;z-index:1000}.alert-error__header{display:flex;align-items:center;margin-bottom:1.5rem}.alert-error__label{margin-bottom:0}.alert-error__close{margin-left:auto;cursor:pointer;font-size:2.4rem}.alert-error__content{position:absolute;left:1rem;right:1rem;padding:2rem 1.5rem 2rem 1.5rem;border-radius:8px;background:#fff;box-shadow:0px 5px 5px rgba(0,0,0,.2),0px 8px 10px rgba(0,0,0,.14),0px 3px 14px rgba(0,0,0,.12);overflow:auto;max-width:50rem;margin:0 auto}.alert-error__action{margin-bottom:0}
.account{padding-top:var(--content-padding-top)}.account__title{margin-top:2rem}
.menu-list{margin:0 auto;max-width:var(--width-desktop)}
.menu-list__item{border-bottom:1px solid #ddd;display:flex;align-items:center;padding:1.6rem 2rem;font-size:1.6rem;cursor:pointer}.menu-list__item:last-child{border-bottom:none}.menu-list__label{margin-right:auto;color:#757575}.menu-list__icon{color:#757575;font-size:2rem}
.account-password{padding-top:var(--content-padding-top)}
.appointment-view{padding-top:var(--content-padding-top);display:flex;margin:0 1rem}.appointment-view-left{flex:0 0 30rem;padding-right:1rem}.appointment-view-right{flex:auto;border-left:1px solid #dfdfdf;padding-left:1rem}.appointment-view-patient__name{font-size:2rem;margin-bottom:.5rem}.appointment-view-patient__phoneNumber{margin-bottom:1rem}.appointment-view-patient__height-weight{display:flex;justify-content:space-between}.appointment-view-patient-medical{margin-bottom:1rem}.appointment-view-patient-medical__title{font-size:1.3rem;display:flex;color:var(--color-primary);margin-bottom:.5rem;margin-top:1rem}.appointment-view-patient-medical__item{margin-bottom:1rem;display:flex;align-items:center}.appointment-view-patient-medical__icon{font-size:2rem;margin-right:1rem}.appointment-view-patient-medical__icon--checked{color:#8bc34a}.appointment-view-patient-medical__icon--none{color:#eb5757}.appointment-view-details{display:flex;margin-bottom:2rem;align-items:center}.appointment-view-details__date-time{margin-right:3rem}.appointment-view-details__date-time>div{margin-bottom:.2rem}.appointment-view-details__actions{margin-left:auto;display:flex;min-width:50rem}.appointment-view-details__join-appointment{margin-left:auto}.appointment-view__banner{border:1px solid var(--color-primary);border-radius:7px;padding:1rem;color:var(--color-primary);font-size:1.8rem;display:flex;align-items:center}.appointment-view__banner-additional-text{font-style:italic;font-size:1.3rem}.appointment-view__banner-actions{margin-left:auto;text-align:right}.appointment-view__banner-action{margin-left:1rem;margin-bottom:0;min-width:12rem}
.alert-confirm{background:rgba(0,0,0,.5);position:fixed;top:0;bottom:0;left:0;right:0;display:flex;align-items:center;z-index:1000}.alert-confirm__header{display:flex;align-items:center;margin-bottom:1.5rem}.alert-confirm__label{margin-bottom:0}.alert-confirm__close{margin-left:auto;cursor:pointer;font-size:2.4rem}.alert-confirm__content{position:absolute;left:1rem;right:1rem;padding:2rem 1.5rem 2rem 1.5rem;border-radius:8px;background:#fff;box-shadow:0px 5px 5px rgba(0,0,0,.2),0px 8px 10px rgba(0,0,0,.14),0px 3px 14px rgba(0,0,0,.12);overflow:auto;max-width:50rem;margin:0 auto}.alert-confirm__actions{display:flex;align-items:center;justify-content:space-between}.alert-confirm__action{width:48%;margin-bottom:0}
.appointment-view__block{margin-bottom:2rem}.appointment-view__block-content{border:1px solid #dfdfdf;border-radius:7px;padding:1rem}.appointment-view__block-title{font-size:1.6rem;margin-bottom:1rem;color:var(--color-primary)}.appointment-view__patient-note{margin-bottom:2rem}.appointment-view__patient-note p{min-height:1.3rem}.appointment-view__preview-gp-letter{margin-top:2rem}
.form-text-area{margin-bottom:1rem}.form-text-area__label{font-size:1.3rem;color:var(--color-text-primary)}.form-text-area__field{height:15rem;resize:none;font-size:var(--font-size-default);font-weight:400;font-family:var(--font-family);color:var(--color-text-primary);padding:.75rem 1rem;border:1px solid var(--color-primary);border-radius:7px;width:100%;margin-top:.4rem}.form-text-area__field:active,.form-text-area__field:focus{outline:none;border:2px solid var(--color-primary)}.form-text-area__error{display:block;margin-top:.5rem;color:var(--color-error)}
.appointment-view-sidebar-patient__details{margin-bottom:1rem}.appointment-view-sidebar-patient__name{font-size:2rem;margin-bottom:.5rem}.appointment-view-sidebar-patient__phoneNumber{margin-bottom:1rem}.appointment-view-sidebar-patient-medical{margin-bottom:1rem}.appointment-view-sidebar-patient-medical__title{font-size:1.3rem;display:flex;color:var(--color-primary);margin-bottom:.5rem}.appointment-view-sidebar-patient-medical__item{margin-bottom:1rem;display:flex;align-items:center}.appointment-view-sidebar-patient-medical__item+small{position:relative;top:-1rem}.appointment-view-sidebar-patient-medical__icon{font-size:2rem;margin-right:1rem}.appointment-view-sidebar-patient-medical__icon--checked{color:#8bc34a}.appointment-view-sidebar-patient-medical__icon--none{color:#eb5757}.appointment-view-sidebar-patient-medical__link{text-decoration:underline;color:var(--color-text-primary);display:block;margin-bottom:.5rem}.appointment-view-sidebar-details{display:flex;margin-bottom:2rem}.appointment-view-sidebar-details__section{margin-right:3rem}.appointment-view-sidebar-details__section>div{margin-bottom:.2rem}.appointment-view-sidebar-details__actions{margin-left:auto;display:flex}.appointment-view-sidebar-details__join-appointment{margin-left:auto}.appointment-view-sidebar__banner{border:1px solid var(--color-primary);border-radius:7px;padding:1rem;color:var(--color-primary);font-size:1.8rem;display:flex;align-items:center}.appointment-view-sidebar__banner-additional-text{font-style:italic;font-size:1.3rem}.appointment-view-sidebar__banner-actions{margin-left:auto;text-align:right}.appointment-view-sidebar__banner-action{margin-left:1rem;margin-bottom:0;min-width:12rem}
.appointment-view-appointment-form{margin-top:1rem}.appointment-view-appointment-form__actions{display:flex;justify-content:flex-end;align-items:center;margin-bottom:.5rem}.appointment-view-appointment-form__actions .button{margin-bottom:1rem;margin-left:1rem}.appointment-view-appointment-form-practitionerNotesForPatient .form-text-area__field{height:20rem}.appointment-view-appointment-form-practitionerNotesForGpLetter .form-text-area__field{height:35rem}.appointment-view-appointment-form-practitionerNotesForClinic .form-text-area__field{height:20rem}.appointment-view-appointment-form__field-label{margin-top:4rem;margin-bottom:.25rem;color:var(--color-text-primary)}.appointment-view-appointment-form__field-label{margin-top:4rem;margin-bottom:.25rem;color:var(--color-text-primary)}.appointment-view-appointment-form__field-label--top{margin-top:0}.appointment-view-appointment-form .unsaved-changes{color:var(--color-error-text)}
.form-success{margin-bottom:2rem;background:var(--color-success-background);color:var(--color-success-text);padding:1.5rem;border-radius:6px;white-space:pre-wrap}.form-success p{margin-bottom:1.5rem}.form-success p:last-child{margin-bottom:0}.form-success a{color:var(--color-error-text);text-decoration:underline}
.patient-notes-editor{font-size:var(--font-size-default);font-weight:400;font-family:var(--font-family);color:var(--color-text-primary);padding:.75rem 1rem;border:1px solid var(--color-primary);border-radius:7px;width:100%;margin-top:.4rem}.patient-notes-editor .DraftEditor-root{max-height:1000rem;overflow-y:scroll}
.unsupported-browser{display:flex;flex-direction:column;align-items:center;padding-bottom:2rem;margin:5rem auto 0 auto}.unsupported-browser-info__logo{margin:2rem auto 2rem auto;max-width:17rem}.unsupported-browser-info__title{font-size:3.2rem;line-height:3.8rem;color:var(--color-primary);font-weight:300;margin:2rem 2rem 2rem 2rem;text-align:center}
.page-not-found{display:flex;flex-direction:column;align-items:center;padding-bottom:2rem;margin:5rem auto 0 auto}.page-not-found-info__logo{margin:2rem auto 2rem auto;max-width:17rem}.page-not-found-info__title{font-size:3.2rem;line-height:3.8rem;color:var(--color-primary);font-weight:300;margin:2rem 2rem 2rem 2rem;text-align:center}.page-not-found__home{display:flex;justify-content:center}
