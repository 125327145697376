.home {
  padding-top: var(--content-padding-top);

  &__title {
    font-size: 2rem;
  }

  &__sub-title {
    color: var(--color-text-primary);
    margin-bottom: 2rem;
  }

  &-next-appointments {
    background: rgba(80, 188, 189, 0.07);
    margin-bottom: 2rem;
    padding: 2rem 0;
  }

  &-upcoming-appointments {
    margin-top: 3rem;
  }
}
