.field-select {
  font-size: var(--font-size-default);
  font-weight: 400;
  font-family: var(--font-family);
  color: var(--color-text-primary);
  padding: 0.9rem 1rem;
  border: 1px solid var(--color-primary);
  border-radius: 7px;
  background: transparent;
  height: 42px;
  margin-top: 0.4rem;
  flex: 1;
  margin-left: 1rem;

  &:active,
  &:focus {
    outline: none;
    border: 2px solid var(--color-primary-darker);
  }
}

.field-select:first-child {
  margin-left: 0;
}

.field-select:required:invalid {
  color: gray;
}

.field-select option[value=''][disabled] {
  display: none;
}

.field-select option {
  color: black;
}
