.login {
    &-content {
        padding-top: var(--content-padding-top);
    }

    &-actions {
        margin-top: 5rem;
        display: block;
    }
}
