@keyframes button-spinner {
    to {
        transform: rotate(360deg);
    }
}

.button {
    border-radius: 6px;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 1rem;
    cursor: pointer;
    border: none;

    &--size-small {
        padding: 0.6rem 1.6rem;
        font-size: 1.3rem;
        line-height: 1.8;
    }

    &--size-medium {
        padding: 0.4rem 1rem;
        font-size: 1.3rem;
        line-height: 1.8;
    }

    &--size-large {
        padding: 1rem 1.6rem;
        font-size: 1.8rem;
        line-height: 2.2;
    }

    &--color-primary {
        background: var(--color-primary);
        box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14),
        0px 1px 5px rgba(0, 0, 0, 0.12);
        color: #ffffff;

        &:hover {
            background: var(--color-primary-darker);
        }
    }

    &--color-primary-outline {
        border: 1px solid var(--color-primary);
        background: transparent;
        color: var(--color-primary);
    }

    &--color-primary-link {
        border: none;
        background: transparent;
        color: var(--color-primary);
        text-transform: none;
    }

    &--color-secondary {
        background: #fff;
        box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14),
        0px 1px 5px rgba(0, 0, 0, 0.12);
        color: var(--color-primary);
    }

    &--color-secondary-outline {
        border: 1px solid #fff;
        background: transparent;
        color: #fff;
    }

    &--color-danger-outline {
        border: 1px solid #ee6c6c;
        background: transparent;
        color: #ee6c6c;
    }

    &.w-full {
        width: 100%;
    }

    &:disabled, .disabled {
        background: #c4c4c4;
        color: #808080;
        opacity: 0.8;
        border: 1px solid #808080;
        cursor: not-allowed;
        box-shadow: none;
    }

    &.loading {
        background: transparent;
        color: transparent;
        border: 1px solid #c4c4c4;
        opacity: 0.8;
        cursor: progress;
        box-shadow: none;
        position: relative;

        &:before {
            content: '';
            box-sizing: border-box;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 20px;
            height: 20px;
            margin-top: -10px;
            margin-left: -10px;
            border-radius: 50%;
            border: 2px solid var(--color-primary-darker);
            border-top-color: var(--color-primary);;
            animation: button-spinner .6s linear infinite;
        }
    }

}
