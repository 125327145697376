.appointment-view-appointment-form {
    margin-top: 1rem;

    &__actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 0.5rem;
    }

    &__actions .button {
        margin-bottom: 1rem;
        margin-left:  1rem;
    }

    &-practitionerNotesForPatient .form-text-area__field {
        height: 20rem;
    }

    &-practitionerNotesForGpLetter .form-text-area__field {
        height: 35rem;
    }

    &-practitionerNotesForClinic .form-text-area__field {
        height: 20rem;
    }

    &__field-label {
        margin-top: 4rem;
        margin-bottom: 0.25rem;
        color: var(--color-text-primary);
    }

    &__field-label {
        margin-top: 4rem;
        margin-bottom: 0.25rem;
        color: var(--color-text-primary);

        &--top {
            margin-top: 0;
        }
    }
    .unsaved-changes {
        color: var(--color-error-text);
    }
}
