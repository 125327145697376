.page-header {
    display: flex;
    align-items: center;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-bottom: 1rem;
    position: fixed;
    width: 100%;
    background: #fff;
    top: 0;
    left: 0;
    border-bottom: 1px solid #dfdfdf;
    z-index: 999;

    &__left {
        display: flex;
        align-items: center;
        min-width: 30rem;
    }

    &__back {
        margin-right: 0.5rem;
        cursor: pointer;
        color: var(--color-primary);
        font-size: 2.4rem;
        order: 1;
        margin-top: 1rem;
    }

    &__title {
        margin: 1rem 0 0 0 !important;
        order: 2;
        font-size: 2rem;
    }

    &__logo {
        background-color: transparent;
        border: none;
        margin: 1rem 1rem 0 0;
        width: 7rem;
        order: 0;
    }

    &-info {
        border: 1px solid var(--color-primary);
        border-radius: 5px;
        color: var(--color-primary);
        padding: 0.5rem 1rem 1rem 1rem;
        display: flex;
        border-radius: 10px;
        margin: 0.5rem auto 0 auto;

        &__block {
            margin-right: 8rem;

            &:last-child {
                margin-right: 0;
            }
        }

        &__title {
            margin-bottom: 1rem;
        }

        &__time {
            font-size: 3rem;
        }

        &__extra {
            margin-bottom: 0.5rem;
        }
    }
}
