.text-title {

    &--main {
        font-size: 2rem;
        color: var(--color-primary);
        margin-bottom: 2rem;
    }

    &--sub {
        font-size: 1.6rem;
        color: var(--color-primary);
        margin-bottom: 1.2rem;
    }

    &.mb-10 {
        margin-bottom: 1rem;
    }
}
